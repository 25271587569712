import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api.miros.services',
});

export async function getArticlesGraphQl({ pool_id }) {
  const query = `
    query Recommendations($pool_id: String) {
      recommendations(query: {pool_id: $pool_id, customer_id: "e8f87a4c-7591-4e85-b5d9-11827df3aa81", integration_id: "e3a61995-6b7c-4d9f-a2cf-e88b687760ce", defaultSizeFilter: true}) {
        id
        price
        price_discounted
        color_name
        image
        image_optimized
        image_optimized_full
        name
        alias
        sizes
        brand
        pool_id
        customer_id
        url
        engine
      }
    }
  `;

  const variables = {
    pool_id,
    customer_id: window.CUSTOMER_ID,
  };

  const response = await api.post('/graphql', {
    query,
    variables,
  });

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response);
    return [];
  }

  return response.data.data.recommendations;
}

export async function getArticles({
  amount, pool_id, id, alias, query,
}) {
  const response = await api.get('/recommendations', {
    params: {
      pool_id,
      id,
      alias,
      customer_id: window.CUSTOMER_ID,
      items: amount,
      skipSizeFilter: true,
      ...query,
    },
  });

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response);
    return [];
  }

  return response.data;
}

export async function getArticleByAlias(alias) {
  const response = await api.get('/items', {
    params: {
      alias,
    },
  });

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response);
    return null;
  }

  let article;
  if (Array.isArray(response.data)) {
    [article = null] = response.data;
  } else {
    [article = null] = response.data.data;
  }

  return article;
}

export async function fetchCustomerId(customer) {
  const response = await api.get(
    `/pools/${customer.pool_id}`,
  );

  if (response.status < 200 && response >= 300) {
    // eslint-disable-next-line no-console
    console.error(response);
    return null;
  }

  return response.data.customer_id;
}

export async function getRecommendationsByNLP({ text_query, amount, pool_id }) {
  const config = {
    params: {
      items: amount,
      text_query,
      pool_id,
      engine: 'SemanticTextQdrant',
      customer_id: window.CUSTOMER_ID,
      skipSizeFilter: true,
    },
  };

  if (sessionStorage.getItem('lang')) {
    config.params.text_query_language = sessionStorage.getItem('lang');
  }

  const response = await api.get('/recommendations', config);

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response);
    return [];
  }

  return response.data;
}

export async function getDemoByName({ slug }) {
  const response = await api.get('/demos', {
    params: {
      slug,
    },
  });

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response);
    return {};
  }

  if (!response?.data.data[0]) {
    location.replace('https://miros.ai');
  }
  return response?.data.data[0];
}
